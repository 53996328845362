import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import API from '@/api/teacher/center/courseList.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default 
{
    // 页面名称
    name: "colleague",
    data()
    {
        return{
            // 列表数据
            list:[],
            // 数据总数
            listTotal:0,
            // 根据内容搜索
            searchContent:"",
            // 当前页码数
            currentPage:1,
            // 当前一页显示多少条数据
            pageSize:10,
            // 教师列表
            teacherList:[],
            // 教师id
            teacher_id:'',
        }
    },
    created()
    {
        this.getData()
    },
    watch:
    {
        /**
         * 分页
         */
        currentPage(newVal,oldVal)
        {
            this.getData();
        }
    },
    methods:
    {
        getData()
        {
            try 
            {
                let self = this;
                // 请求参数
                let data = {
                    search : self.searchContent,
                    page : self.currentPage,
                    pageSize: self.pageSize,
                    teacher_id:self.teacher_id,
                };
                nprogressStart();
                self.$Loading.show();
                // 获取课程列表请求
                API.showCenterCourseList(data)
                    .then((result)=>{
                        nprogressClose();
                        self.$Loading.hide();
                        if(result.code > 0 )
                        {
                            self.list = result.list
                            self.listTotal = result.total;
                            self.teacherList = result.centerUserList;
                        }
                    })                
            } 
            catch (error) 
            {
                Message.error('系统错误') 
            }
        },
        searchMember(e)
        {
            this.currentPage = 1;
            this.getData(); 
        },
    }       
};