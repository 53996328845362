<template >
    <div class="logList">
        <div class="logButton">
            <!-- <div class="export">
                <el-button type="primary" @click="dialogVisible = true">添加成员</el-button>
            </div> -->
            <div class="search">
                <div class="searchContent">
                    <el-select v-model="teacher_id" placeholder="请选择教师">
                        <el-option label="全部" value="" >
                        </el-option>
                        <el-option
                            v-for="item in teacherList"
                            :key="item.user_id"
                            :label="item.name"
                            :value="item.user_id">
                        </el-option>
                    </el-select>
                </div> 
                <el-button type="primary" @click="searchMember">搜索</el-button>
            </div>
        </div>
        <!-- 列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                    </el-table-column>
                    <!-- 课程名称 -->
                    <el-table-column
                        label="课程名称"
                        prop="courseName"
                        width="400">
                    </el-table-column>
                    <!--姓名  -->
                    <el-table-column
                        prop="teacherName"
                        label="教师名称"
                        width="100">
                    </el-table-column>
                    <!--绑定班级 -->
                    <el-table-column
                        prop="classStr"
                        label="绑定班级">
                    </el-table-column>
                    <!--绑定班级 -->
                    <el-table-column
                        prop="projectStr"
                        label="关联项目">
                    </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="listTotal">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/teacher/center/courseList.js";
    export default {
        ...logList
    }
</script>
<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/center/courseList.scss";
</style>